@media print {

  header { display: none; }

  footer, div.member, ul.hlink { display: none; }

  div.notesPage { border: 0; }
  div.notesPage div.pdf { display: none; }
  div.notesPage div.presentation div.hdr a.speaker-sm  { display: none; }

  div.notesPage div.presentation { margin: 0 0 5px 0; border-bottom: 0; }

  div.text-link { display: none; }

  body { background-image: none; background-color: #FFF; }

  div.rolenav { display: none; }



}