//$orange: #F7941D;
$orange: #E86D1F;

div.arch-sale-page {

  text-align: center;

  header {
    margin-bottom: 40px;
    h1 { margin-bottom: 10px; text-transform: uppercase; font-size: 36px; }
    h3 { font-size: 28px; }
    h3.ends { color: #cc0000; }
    span.flash { color: $orange; }
  }

  //p {  max-width: 700px; margin: 0 auto 40px auto; }

  div.info {
    text-align: left;
  }

  a.orange-button {
    background: $orange;
    padding: 10px 15px;
    color: #fff;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 60px;
  }
  a.orange-button.register {
    text-transform: uppercase;
    font-size: 22px;
  }

  div.bundle {
    //max-width: 400px;
    //margin: 0 auto 40px auto;
    margin-bottom: 20px;
    //text-align: left;
  }

  div.button-wrapper {
    text-align: center;
  }

  div.gift-card {
    text-align: center;
    max-width: 100%;
    img { max-width: 100%; border: 1px solid #999; border-radius: 8px; }

  }

  div.oculus-wrapper {
    text-align: center;
    max-width: 100%;
    img { max-width: 100%; border: 1px solid #999; border-radius: 8px; }
  }

  div.watch-wrapper {
      max-width: 500px;

      table.watches {
        max-width: 100%;
         img { max-width: 154px; }
      }

  }

}