
div.intro-2020 {

  div.right-side {
    padding-top: 100px;
  }


  @media only screen and (max-width: 991px) {
    div.right-side {
      padding-top: 0;
      padding-bottom: 40px;
    }
  }

}