body.show-home {

  div.level.vshow {
    background: #C5C4C4;

    @media (min-width: 576px) {
      .container { max-width: 100%; }
    }
    @media (min-width: 768px) {
      .container { max-width: 100%; }
    }
    @media (min-width: 1200px) {
      .container { max-width: 1140px; }
    }
  }

}
