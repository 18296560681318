$dark-blue: #212121;
$light-blue: #4C6AEF;
$orange: #F7941D;

body.show-home {

  div.body-wrapper {
    background: #fff url('2019/cto/cto_bg_1920.jpg') no-repeat center top;
    @media only screen and (max-width: 1500px) {
      background: #fff url('2019/cto/cto_bg_1500.jpg') no-repeat center top;
    }
    @media only screen and (max-width: 1000px) {
      background: #fff url('2019/cto/cto_bg_1000.jpg') no-repeat center top;
    }
    @media only screen and (max-width: 800px) {
      background: #fff url('2019/cto/cto_bg_800.jpg') no-repeat center top;
    }

  }


  div.intro {
    padding: 80px 0 80px 0;
    vertical-align: top;

    div.label-col { width: 60%; display: inline-block; margin: 0; padding: 0 15px 0 0; }
    div.countdown-col {
      width: 40%;
      //display: inline-block; margin: 0; padding: 0 0 0 0; text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;

      div.countdown-col-cell {
        width: 100%;
        //display: flex;
        //width: 100%;
        //height: 100%;
        //align-items: center;
        //justify-content: center;
      }

    }

    @media only screen and (max-width: 1000px) {
      padding: 30px 15px 40px 15px;
      div.label-col { width: 100%; margin-bottom: 30px;  }
      div.countdown-col { width: 100%; padding: 0;
        div.reg-includes, div.count-down-wrapper {
          margin: 0 auto 10px auto;
        }
      }
    }

    div.triple {
      border-radius: 4px;
      border: 1px solid $light-blue;
      background: rgba(255, 255, 255, 0.1);
      padding: 10px;
      color: #fff;
      margin: 0 auto 40px auto;
      text-align: center;

      h4 { text-align: center; font-size: 18px; color: #fff; font-weight: bold; }
      p { margin-bottom: 5px; }
      p:last-child { margin-bottom: 0; }

      a { color: yellow; }

      div.offer {
        text-align: left;
        h5 { font-size: 16px; color: yellow; }

      }
      a.orange-button {
        color: #fff;
        margin-bottom: 10px;
        display: inline-block;
      }


    }

    div.video-replay {
      display: inline-block;
      max-width: 300px;
      background: #fff;
      padding: 15px 10px;
      border: 1px solid $light-blue;
      border-radius: 4px;
      margin: 0 0 20px 0;
      color: #000;
      text-align: center;
      h4 { font-size: 16px; font-weight: 700; margin-bottom: 5px; color: #000; }
    }


    div.intro-full-day-workshop {
      padding: 10px;
      background: #fff;
      border: 1px solid $light-blue;
      border-radius: 4px;
      display: table;

      h3 { font-size: 22px; text-align: center; margin-bottom: 5px; }
      h4 { font-size: 16px; }

      div.image-wrapper, div.details {
        display: table-cell;
        vertical-align: top;
      }

      div.image-wrapper {
        padding-right: 10px;
        text-align: center;
        a.speaker-circle-md {
          img { width: 100px; height: 100px;   }
        }
      }

      h3 { font-size: 18px; }
      h4 { font-size: 16px; }

      p { color: #cc0000; font-size: 14px; }

      div.speaker-circle-md {
        max-width: 100px;
      }

    }

    div.label-wrapper {
      color: #fff;
      h1 {
        font-size: 100px; line-height: 100px; margin-bottom: 10px; color: $light-blue;
        span { color: #fff; }
      }
      h2 { font-size: 24px; margin-bottom: 40px; }

      p { margin-bottom:20px; font-size: 18px; }
      p.three-free, p.early-bird { color: yellow; margin-bottom:30px; }


      a.blue-button {
        margin-bottom: 20px;
      }

      @media only screen and (max-width: 1200px) {
        h1 { font-size: 75px; line-height: 75px; }
        h2 { margin-bottom: 20px; }
      }
      @media only screen and (max-width: 768px) {
        h1 { font-size: 48px; line-height: 48px; }
        h2 { font-size: 18px; }
        p { font-size: 16px;}
      }

    }

    div.five-for-five {

      //max-width: 300px;
      background: #fff;
      color: #333;
      border-radius: 4px;
      text-align: center;
      padding: 15px;
      font-size: 14px;
      margin-bottom: 30px;

      table { width: 100%; }

      em { color: #cc0000; }

      h3 { color: $orange; font-size: 24px; font-weight: bold; }
      ul {
        margin: 0 0 10px 0;
        list-style: none;
      }

      a.orange-button {
        background: $orange;
        display: inline-block;
        color: #FFF;
        padding: 10px 15px;
        border-radius: 4px;
        margin-bottom: 10px;
      }
      a.orange-button:hover {
        color: yellow;
        text-decoration: none;
      }

    }

    div.count-down-wrapper {
      max-width: 500px;
      width: 100%;
      background: rgba(76, 106, 239, 0.4);
      color: #fff;
      border-radius: 4px;
      margin-bottom: 20px;

      div.counter {
        padding: 20px;

        p.date-message { font-size: 12px; margin-bottom: 5px; color: $orange; font-weight: bold; text-align: center; }

        table {
          width: 100%;
          td {
            width: 25%;
            padding: 3px;

            div {
              text-align: center;

              h4 {
                font-size: 48px;
                font-weight: 700;
                margin-bottom: 0;
              }

              h5 {
                font-size: 16px;
              }
            }

          }
        }

        @media only screen and (max-width: 815px) {
          padding: 10px;
          table {
            td {
              div {
                h4 { font-size: 28px; }
                h5 { font-size: 14px; }
              }
            }
          }
        }

      }

      div.stats {
        width: 100%;
        background: #000000;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        display: table;


        div.item {
          display: table-cell;
          vertical-align: top;
          width: 50%;

          svg {
            display: inline-block;
            margin-right: 5px;
            margin-top: -20px;
            width: 40px; height: 40px;
            background: #999;
            padding: 10px;
            border-radius: 25px;
            path { fill: #fff; }
          }

          div.info {
            display: inline-block;
            //display: none;
            h4 { color: $light-blue; font-size: 18px; margin-bottom: 0; }
            p { font-size: 14px; margin-bottom: 0; }
          }

        }
        div.item.seats {
          border-right: 1px dotted #999;
          padding: 20px 0 20px 20px;
        }
        div.item.speakers {
          padding: 20px 0 20px 20px;
        }

        @media only screen and (max-width: 600px) {
          div.item {
             svg { width: 20px; height: 20px; padding: 5px; }
             div.info {
               h4 { font-size: 16px; }
               p { font-size: 12px; }
             }
          }
        }


      }

    } /* countdown wrapper */

    div.special-offer {

      background: rgba(255, 255, 255, 0.7);
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #999;
      text-align: center;
      h3 { color: $light-blue; }

    }

  }

  div.replay-last-year {
    text-align: center;
    border-bottom: 1px solid #999;
    background: #fff;
    a.replay {
      padding: 10px 25px;
      background: #43cc00;
      color: #fff;
      display: inline-block;
      border-radius: 4px;
      font-size: 24px;
    }
  }


}