
@use "theme-2015";

@use "site-cto" as site;

@use "theme-tls-intro";
@use "theme-vshow";

@use "show-view-cto";

@use "theme-tls-2020";
@use "theme-tls-2021";

@use "theme-arch-sale-page";

@use "print";

div.container.body {
   padding: 100px 0 0 0;
}

h1, h2, h3, h4, h5 { font-family: 'Ropa Sans'; }

div.level {
   padding: 60px 0;
   h2 { font-size: 48px; text-align: center; margin-bottom: 30px; }

   @media only screen and (max-width: 600px) {
      h2 { font-size: 32px; }
   }
   @media only screen and (max-width: 400px) {
      h2 { font-size: 24px; }
   }
}






body.show-home {

   div.home-link-wrapper {
      display: none;
   }

   div.lead-message {
      background: #fff;
      border-bottom: 1px solid #999;

      span { color: site.$light-blue; }

      div.header {
         max-width: calc(40% - 15px);
         margin: 0; padding: 0 25px 0 0;
         float: left;
         text-align: right;

         h2 {
            text-align: right;
         }

         @media only screen and (max-width: 768px) {
            h2 { font-size: 32px; }
         }
         @media only screen and (max-width: 400px) {
            h2 { font-size: 24px; }
         }


      }

      div.message {
         max-width: calc(60% - 15px);
         margin: 0; padding: 0 0 0 25px;
         float: left;
         border-left: 1px dotted #999;

         p:last-child { margin-bottom: 30px;
            a { text-decoration: underline; }
         }

         h3 { color: site.$light-blue; }

         ul {
            list-style: none;
            margin: 0; padding: 0;
         }

      }

      @media only screen and (max-width: 500px) {
         div.header {
            max-width: 100%;
         }
         div.message {
            max-width: 100%;
         }
      }

   } /* lead-message */

   div.hybrid-info {
      padding: 30px 0;
      border-bottom: 1px solid #999;
      background: #fff;
      h3 { text-transform: uppercase; font-size: 28px; color: site.$light-blue; }
   }

   div.feature-workshops {
      background: site.$light-blue;
      padding: 30px 0;

      header {
         text-align: center;
         color: #fff;

         h3.deadline {
            color: #fff;
            font-size: 28px;
            span { color: yellow; }
         }
      }


      a.workshop {
         display: block;
         height: 100%;
         background: #fff;
         color: #000;
         border-radius: 8px;
         padding: 15px;
         border: 3px solid theme-2015.$yellow;

         h4 { font-size: 18px; text-align: center; }

         div.info { display: table; width: 100%; }

         div.image-wrapper, div.details {
            display: table-cell;
            //vertical-align: middle;
         }
         div.image-wrapper {
            width: 100px;
            //padding-right: 10px;
            vertical-align: middle;
            img { max-width: 100%; height: auto; border-color: site.$light-blue;  }
         }
         div.details {
            //width: calc(100% - 140px);
            padding-left: 10px;

            h5 { font-size: 16px; }
         }

         p.max { color: #cc0000; font-size: 14px; }

         div.button-wrapper {
            //text-align: center;
            width: 100%;
         }

         div.blue-button {
            display: inline-block;
            padding: 5px 15px;
            background: site.$light-blue;
            color: #fff;
            border-radius: 4px;
         }

      } // a.workshop

      a.workshop:hover {
         background: lightyellow;
         text-decoration: none;
         border: 3px solid theme-2015.$yellow;
         div.blue-button {
            color: yellow;
         }
      }

      @media (max-width: 768px) {
         div.row > div:first-child {
            padding-bottom: 20px;
         }

      }

      @media (max-width: 500px) {
         a.workshop {
            div.image-wrapper {
               max-width: 60px;
               //img { max-width: 50px; }
            }
         }

      }


   } // div.feature-workshops




   div.virtual-workshop-credits {
      background: #f1f1f1;
      border-bottom: 1px solid #999;
      h3 { text-transform: uppercase; font-size: 28px; color: site.$light-blue; margin-bottom: 5px; }
      h4 { margin-bottom: 20px;  color: #cc0000; font-size: 18px; }
      p { max-width: 800px; margin: 0 auto 10px auto;}

      div.button-wrapper {
         margin-top: 20px;
         a.orange-button {
            background: site.$highlight-color;
            color: #fff;
            text-transform: uppercase;
            padding: 10px 25px;
         }
      }

   }

   div.virtual {
      border-bottom: 1px solid #999;
      background: #F8FAFB;


      h3 { font-size: 28px; text-align: center; }

      div.benefits {
         max-width: 700px;
         width: 100%;
         margin: 0 auto 30px auto;
         h4 { font-size: 18px; }
      }

      p { max-width: 800px; margin: 0 auto 20px auto; }

   }

   div.speakers.level {
      background: #F8FAFB;
      text-align: center;
      color: #fff;

      h2 {
         color: site.$light-blue;
         margin-bottom: 30px;
         span { color: #000; }
      }


      div.speaker {
         height: 260px;
         overflow: hidden;
         margin-bottom: 20px;

         a {
            display: inline-block;
            margin-bottom: 10px;
            img {
               border-radius: 50%;
               border: 8px solid site.$faint-blue;
            }
         }
         a:hover {
            img {
              border-color: site.$light-blue;
            }
         }

         h3 { color: #000000; }
         h4.short-bio {
           color: #717279;
           height: 32px;
           overflow: hidden;
           margin-bottom: 0;
         }

         a.bio {
           color: site.$light-blue;
           i { display: none; }
         }
         a.bio:hover {
           color: site.$orange;
         }


      }
      div.speaker:hover {
         a.bio {
            i { display: inline; }
         }
      }


   }  /* speakers */

   div.workshops.level {
      border: 1px solid #999;
      text-align: center;

      p.deadline {
         font-size: 18px;
         text-align: center;
         color: #cc0000;
      }

      p {
         max-width: 800px;
         margin: 0 auto 10px auto;
      }

      div.workshop-detail {

         div.speaker {
            margin-bottom: 30px;

            a.speaker-circle-md img {
               border: 8px solid site.$faint-blue;
            }
            a.speaker-circle-md:hover img {
               border-color: site.$light-blue;
            }

            h4 { font-size: 18px; }
            h5 { font-size: 16px; }
         }

      }


   } /* workshops */

    div.three {
        background: #fff;

        div.col-12 {
           padding-bottom: 15px;
        }

        div.item {
           height: 100%;
           position: relative;
           border: 1px solid #D7D7E3;
           border-radius: 4px;
           padding: 30px 15px 15px 80px;

            img {
               max-width: 50px;
               position: absolute;
               top: 30px;
               left: 15px;
               z-index: 1;
            }

           h3 { margin-bottom: 20px; }

        }
    } /* three */

    div.level.why {

       background: url('2019/cto/why_bg_1920.jpg') no-repeat center top;
       color: #fff;
       padding: 60px 0 80px 0;

       div.box {
          background: rgba(30, 46, 65, 0.5);
          border-radius: 4px;
          padding: 25px;
          max-width: 600px;

          h2 { text-align: left; }

          a { color: #fff; font-size: 32px; color: site.$orange; text-decoration: underline; }
          a:hover { color: yellow; }

       }

       @media only screen and (max-width: 1500px) { background: url('2019/cto/why_bg_1500.jpg') no-repeat center top; }
       @media only screen and (max-width: 1000px) { background: url('2019/cto/why_bg_1000.jpg') no-repeat center top; }
       @media only screen and (max-width: 800px) { background: url('2019/cto/why_bg_800.jpg') no-repeat center top; }

    }

    div.contact {

       text-align: center;
       border-bottom: 1px solid #999;

         div.email-group {
            margin-bottom: 40px;

            form {
               display: inline-block;
               input[type='text'] { color: site.$light-blue !important; padding: 5px; font-size: 22px; border-radius: 3px; margin-right: 10px; }
               input.first-name { max-width: 150px; }
               input.last-name { max-width: 150px; }
               input.email { max-width: 200px; }

               button { background: site.$light-blue; border-width: 0; color: #fff; padding: 7px 25px; font-size: 22px; border-radius: 4px; }
            }

            @media only screen and (max-width: 768px) {
               form {
                  input[type='text'] { font-size: 16px; padding: 2px; }
                  input.first-name { max-width: 120px; }
                  input.last-name { max-width: 120px; }
                  input.email { max-width: 120px; }
                  button { font-size: 14px; padding: 5px 15px; }
               }
            }

            @media only screen and (max-width: 600px) {
               form {
                  input[type='text'] { display: block; margin-bottom: 10px; }
                  input.first-name { max-width: 300px; }
                  input.last-name { max-width: 300px; }
                  input.email { max-width: 300px; }
                  button { display: block; }
               }
            }

         }

         div.social-links {

            div.link-wrapper {
               display: inline-block;

               div.app, div.social {
                  display: inline-flex;
                  flex-direction: row;
                  align-content: center;
                  height: 45px;
                  h3 {
                     margin: auto 10px auto 0; padding: 0;
                     }
               }

               div.app {
                  margin-right: 30px;
                  padding-right: 30px;
                  border-right: 1px dotted #999;
               }

               a { margin-right: 30px;  }
               a:last-child { margin-right: 0; }
               i { font-size: 42px; display: inline-block; }

               @media only screen and (max-width: 600px) {
                  div.app, div.social { display: block; }
                  div.app { border-right: 0; margin: 0 0 60px 0; padding: 0; }
               }

            }


         }

    } /* contact */

   div.include {
      background: #FBFBFB;

      div.row { max-width: 1000px; margin: 0 auto 0 auto; }

      h2 { margin-bottom: 40px; }

      div.col-sm-4 {
         text-align: center;
         margin-bottom: 30px;
         i {
            display: inline-block;
            color: site.$light-blue;
            font-size: 68px;
            margin-bottom: 10px;
         }

      }

      @media only screen and (max-width: 600px)  {
         div.col-sm-4 {
            i {
               font-size: 36px; margin-bottom: 5px;
            }
         }
      }

   }

   div.venue {
      background: url('2019/cto/travel_bg_1920.jpg') no-repeat center top;
      color:#fff;
      padding: 100px 0;
      text-align: center;
      font-size: 18px;
      line-height: 24px;

      h2 { color: #fff; }
      p { max-width: 900px; margin: 0 auto 0 auto; }

      @media only screen and (max-width: 1500px) { background: url('2019/cto/travel_bg_1500.jpg') no-repeat center top; }
      @media only screen and (max-width: 1000px) { font-size: 16px; line-height: 22px; background: url('2019/cto/travel_bg_1000.jpg') no-repeat center top; }
      @media only screen and (max-width: 800px) { background: url('2019/cto/travel_bg_800.jpg') no-repeat center top; }
   }

   div.map {
      background: url('2019/cto/map_bg_1920.jpg') no-repeat center center;
      min-height: 360px;

      text-align: center;

      div.venu-info {
         padding: 40px;
         background: #fff;
         //display: inline-block;
         position: relative;
         top: -120px;
         border-radius: 4px;
         font-size: 22px;

         i { color: site.$light-blue; font-size: 32px; }

      }

      @media only screen and (max-width: 1500px) { background: url('2019/cto/map_bg_1500.jpg') no-repeat center center; }
      @media only screen and (max-width: 1000px) { background: url('2019/cto/map_bg_1000.jpg') no-repeat center center; }
      @media only screen and (max-width: 800px) { background: url('2019/cto/map_bg_800.jpg') no-repeat center center; }

   }

   div.family {

      div.container {

         display: flex;

         h3 {
            font-size: 32px;
            display: inline-block;
            border-right: 1px dotted #999;
            padding: 0 30px 0 0;
            margin-right: 30px;
         }

         p {
            display: inline-block;
            max-width: 60%;
         }

         @media only screen and (max-width: 600px) {
            display: block;
            h3 { border-right: 0; }
            p { max-width: 100%; }
         }
      }

   }

   div.travel-package {
      background: site.$light-blue;


      color: #fff;
       div.container {
          display: flex;
          h3 { font-size: 36px; padding: 0 30px 0 0; margin: 0 30px 0 0; border-right: 1px dotted #999; }
          p { margin: auto 0; }

          @media only screen and (max-width: 600px) {
             display: block;
             h3 { border-right: 0; }
             p {  }
          }
       }
   }

   div.sponsors {
      div.moderne {
         padding-top: 8px;
      }
      div.codescene {
         padding-top: 8px;
      }
   }

   div.past-events {

      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      background: #212121;
      //background: url('2019/cto/travel_bg_1920.jpg') no-repeat center top;

      h3 { color: #fff; font-size: 32px; }

      div.image {
         text-align: center;
         img { max-width: 100%; border: 2px solid site.$orange; border-radius: 4px; }
      }
      div.list {
         ul {
            list-style: none;
            li a { color: #fff; }
            li a:hover { color: site.$orange; }
         }
      }

   } /* past-events */


} /* show home */

body.show-view {

   div.body > div.container {
      padding: 40px 12px;
   }


}

div.travel-information-page {

    div.travel-detail {
       //background: site.$faint-blue;
       //border: 1px solid #999;
       display: flex;
       margin-bottom: 30px;

       div.info {
          display: inline-block;
          padding: 15px;
          width: 40%;
          height: 100%;

          div.address-block {
             margin-bottom: 40px;
             h3 { font-size: 48px; }
          }

          h3 { font-size: 24px; }

          span.rate-period { color: site.$orange; }

          div.item {
             margin-bottom: 30px;
             i { color: site.$light-blue; }
          }

       }

       div.pics {
          display: inline-block;
          width: 60%;

          img  { max-width: 100%; }
       }

       @media only screen and (max-width: 800px) {

              display: block;
              div.info {
                 display: block;
                 width: 100%;
                 div.address-block {
                    h3 { font-size: 32px; }
                 }
              }
             div.pics {
                display: block;
                width: 100%;
             }
       }


    }

}

