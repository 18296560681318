
@use "site-2015";

$dark-blue: #212121;
$light-blue: #4C6AEF;
$light-blue-dim: #dfeafc;
$faint-blue: #E1E3E5;

$highlight-color: #4C6AEF;

$dark-bg: #333;
$orange: #F7941D;

$mobile-size: 993px;

@import url('https://fonts.googleapis.com/css?family=Archivo|Ropa+Sans&display=swap');

body {
   font-family: 'Archivo', Arial, sans-serif;
   font-weight: 300;
   background: #5F5F5F;
}

div.body-wrapper {
  max-width: 1920px;
  background: #fff;
  margin: 0 auto;
}

a { color: $highlight-color; }
a:hover { color: $dark-bg; }

#nav-bar {

  background: $light-blue;

  .nav-links > ul {
    margin-bottom: 5px;

    li {
      a { color: #fff; }
      a:hover { color: $orange; }
    }

    li.nfjs-dropdown {

      ul.nfjs-dropdown-menu {

        li {
          a { color: #000; }
          a:hover { color: $orange; }
        }
      }

    }

    li.register {

      a {
        padding: 8px 15px;
        background: $light-blue;
        border-radius: 4px;
        color: #fff;
      }
      a:hover {  color: $orange; }
    }

  }

  @media only screen and (max-width: $mobile-size) {

    .nav-links > ul {
      li {
        a { color: #000; }
        a:hover { color: $orange; }
      }

      li.register {
        a {
          border: 0; background: transparent;
          padding: 5px;
        }
      }

    }
  }

}


table.itemTable {
  th { background-color: $dark-bg; color: #fff; font-weight: normal; font-size: 1em; }
  tr:nth-child(odd) { background-color: #f1f1f1; }
}


div.home-link-wrapper {
  max-width: 350px;
  float: left;
  font-size: 23px;

  a.home-img {
    display: block;
    float: left;
    padding: 0 10px 0 0;
    svg { max-width: 100%; height: 100%; }
  }

  a.home-tag {
    display: block;
    float: left;
    padding: 7px 0 0 0;
    text-decoration: none;
    color: #fff;
    font-size: 28px;
    span {
      color: $highlight-color;
    }
  }


}

@media only screen and (max-width: 500px) {
  div.home-link-wrapper {
    a.home-img { display: none; }
    a.home-tag { font-size: 18px; margin-left: 10px; }
  }

}



footer {

  background: $dark-blue;
  font-size: 14px;

  div.item {
    text-align: left;
    margin-bottom: 30px;
  }

  ul {
    list-style: none;

  }

  address {
    text-align: left;
  }

  div.nfjs-info {
    text-align: left;
    img { max-width: 200px; display: inline-block; margin-bottom: 10px; }
  }

  @media only screen and (max-width: 1000px) {
    div.nfjs-info {
      img { max-width: 150px; }
    }
  }

}

body.show-home {
  footer {
    background: $dark-blue;
    color: #fff;
    a { color: $light-blue; }
  }
}


body.role-admin div.speaker-app-wrapper {
  padding-top: 0;
}

a.button.button-register {

  display: inline-block;

  background: $light-blue;
  color: #fff;
  padding: 0;
  margin: 0;
  border-radius: 4px;

  //border: 2px solid #D7A55E;
  font-family: 'PT Sans', 'Lato', Arial, sans-serif;
  font-size: 18px;

  div.register {
    display: inline-block;
    padding: 5px 20px;
  }

  div.ticket {
    display: inline-block;
    padding: 5px 20px 5px 20px;
    border-left: 2px solid $highlight-color;
    span {
      top: 4px;
      font-size: 20px;
    }
  }
}
a.button.button-register:hover {
  text-decoration: none;
  background: rgba(222, 87, 62, 0.7);
  color: $highlight-color;
}
