$light-blue: #4C6AEF;
$faint-blue: #E1E3E5;

a.blue-button {
  background: $light-blue;
  color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  display: inline-block;
}

body.show-view {

  #nfjs-drop-down-button {
    path { fill: $light-blue; }
  }


  #nav-bar {

    background: #fff;

    div.home-link-wrapper a.home-tag {
      color: $light-blue;
    }

    .nav-links > ul {

      li {
        a { color: #000; }
        a:hover { color: $light-blue; }
      }
      li.register {
        a { color: #fff; }
      }
    }
  }

  div.show-view-header {
    background: url('2019/cto/speakers_bg_1920.jpg') no-repeat top center;
    min-height: 280px;
    padding-top: 60px;
    text-align: center;
    margin-bottom: 30px;

    color: #fff;
    h3 { text-transform: uppercase; margin-bottom: 40px; font-weight: normal; color: #a0adb5; }
    a { color: #fff; }
    a:hover { color: $light-blue; }

    h1 { text-transform: uppercase; text-align: center; display: block; font-size: 48px; }
  }

  div.show-view-header.schedule {
    background: url('2019/cto/schedule_bg_1920.jpg') no-repeat top center;
  }

  div.body {
    h1 { display: none; }
  }

  a.speaker-circle-md {
    img {
      border: 8px solid $faint-blue;
    }
  }
  a.speaker-circle-md:hover {
    img {
      border-color: $light-blue;
    }
  }

  div.speaker-view {


    @media only screen and (max-width: 768px) {

      .speaker-circle-md {
        img { max-width: 100px; max-height: 100px; }
      }

    }


  }

}

body.show-home, body.show-view {
  div.register-bottom {
    background: url('2019/cto/register_bg_1920.jpg') no-repeat center center;
    color: #fff;
    text-align: center;
    padding: 60px 0;
    p { max-width: 900px; margin: 0 auto 30px auto; }

    @media only screen and (max-width: 1500px) { background: url('2019/cto/register_bg_1500.jpg') no-repeat center center; }
    @media only screen and (max-width: 1000px) { background: url('2019/cto/register_bg_1000.jpg') no-repeat center center; }
    @media only screen and (max-width: 800px) { background: url('2019/cto/register_bg_800.jpg') no-repeat center center; }


  }

}
